@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF-Pro-Rounded";
  src: url("./assets/fonts/SF-Pro-Rounded.ttf");
}

@font-face {
  font-family: "SF-Pro-Rounded-regular";
  src: url("./assets/fonts/SF-Pro-Rounded.ttf");
}

@font-face {
  font-family: "SF-Pro-Rounded-light";
  src: url("./assets/fonts/FontsFree-Net-SF-Pro-Rounded-Light.ttf");
}

@font-face {
  font-family: "SF-Pro-Rounded-med";
  src: url("./assets/fonts/FontsFree-Net-SF-Pro-Rounded-Medium.ttf");
}

@font-face {
  font-family: "SF-Pro-Rounded-bold";
  src: url("./assets/fonts/bold.ttf");
}

* {
  font-family: "SF-Pro-Rounded";
}

.family-light {
  font-family: "SF-Pro-Rounded-light";
}

.family-med {
  font-family: "SF-Pro-Rounded-med";
}

.family-reg {
  font-family: "SF-Pro-Rounded-regular";
}

.family-bold {
  font-family: "SF-Pro-Rounded-bold";
}

#react-qrcode-logo {
  border-radius: 5%;
}

input::placeholder {
  font-family: "SF-Pro-Rounded-light";
}

.progressAnimation {
  animation: progressBarAnimation 20s linear infinite;
}

@keyframes progressBarAnimation {
  0%,
  100% {
    width: 0;
  }

  50% {
    width: 100%;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

button {
  /* background-color: #fafafafa !important; */
}

wui-text {
  color: black !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

.animate-fade-in-ns {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

/* #loading_fill {
  transition: width 1s;
} */
* {
  user-select: none;
}

.Demo__container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 30px);
  max-width: 400px;
  margin: 0 auto;
}

.Demo__some-network {
  text-align: center;
  padding: 5px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

@keyframes pingo {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-pingo {
  animation-name: pingo;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-direction: normal;
  animation-iteration-count: infinite;
}

.wallet-action-button-customized {
  background-color: #fafafa !important;
  color: #8e8e8e !important;
  font-family: "SF-Pro-Rounded-regular" !important;
}

.adapter-dropdown-list {
  background-color: #f0f0f0 !important;
  font-family: "SF-Pro-Rounded-light" !important;
}

.adapter-dropdown-list-item {
  background-color: #f0f0f0 !important;
  color: #8e8e8e !important;
  font-family: "SF-Pro-Rounded-light" !important;
}

.adapter-modal-wrapper {
  background-color: #fafafa !important;
  color: #8e8e8e !important;
  font-family: "SF-Pro-Rounded-regular" !important;
}

.adapter-react-button {
  height: 30px !important;
  background-color: #fafafa !important;
  color: #8e8e8e !important;
  font-family: "SF-Pro-Rounded-regular" !important;
}

.adapter-dropdown {
  height: 30px !important;
}

.adapter-modal-header .close-button {
  background-color: #fafafa !important;
}

.adapter-dropdown-list-item:hover {
  background-color: #fafafa !important;
}

.adapter-react-button .button-icon {
  width: 25px !important;
  height: 25px !important;
  overflow: hidden !important;
}
